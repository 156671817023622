<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="800px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        Selecione um mês de referência
        <v-spacer/>
        <v-btn icon @click="value = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6" >
              <strong>Tipo da Fatura</strong>
              <v-radio-group v-model="tipoFatura" row 
                  dense
                  :rules="[
                        this.$validators.string.required,
                  ]">
                <v-radio
                  label="Mensalidade"
                  value="MA"
                ></v-radio>
                <v-radio
                  label="Locação"
                  value="AL"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <br>
              <v-text-field label="* Mês referência"
                    dense
                    type="month"
                    :max="dataMaxima()"
                    :min="dataMinima()"                    
                    v-model="mesRef"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
            </v-col>
            <v-col cols="12" sm="12" v-show="tipoFatura === 'MA'">
              <search-box
                  :hidden-clear="false"
                  label="Local"
                  popup-label="Selecione um local"
                  :id.sync="idLocal"
                  :descricao.sync="descricao_idLocal"
                  :value="local"
                  :loader-fn="loaderLocal"
                  item-key="id"
                  item-text="descricao">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum local encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="12" sm="6" v-show="tipoFatura === 'AL'">
              <v-radio-group v-model="tipoLocacao" row 
                  dense>
                <v-radio
                  label="Outras"
                  value="OU"
                ></v-radio>
                <v-radio
                  label="Grupo"
                  value="GR"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" v-show="tipoLocacao === 'GR' && tipoFatura === 'AL'">
              <search-box
                  :hidden-clear="false"
                  label="Grupo"
                  popup-label="Selecione um Grupo"
                  :id.sync="idGrupoLocacao"
                  :nome.sync="nome_idGrupoLocacao"
                  :value="grupoLocacao"
                  :loader-fn="loaderGrupoLocacao"
                  item-key="id"
                  item-text="nome">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Grupo encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="value = false">Cancelar</v-btn>
        <v-btn color="primary" @click="gerar" :disabled="!value">Gerar Faturas</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {gerarFaturasMensal} from "../../../api/faturas";
import {findAll as findAllLocais} from "../../../api/locais";
import {findAll as findAllGrupoLocacao} from '../../../api/grupoLocacao';
import {ApiError} from "../../../api/core";
import SearchBox from "../../../components/shared/common/SearchBox";
import * as moment from 'moment';

export default {
  name: "GerarFaturasMensalDialog",
  components: {
    SearchBox
  },
  data () {
    return {
      valid: true,
      value: false,
      mesRef: null,
      idLocal: null,
      descricao_idLocal: "",
      idGrupoLocacao: null,
      nome_idGrupoLocacao: "",
      tipoFatura: "",
      tipoLocacao: "",
      loaderLocal(page, search) {
        return findAllLocais(page, {
          descricao: search
        })
      },
      loaderGrupoLocacao(page, search) {
        return findAllGrupoLocacao(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    local() {
      if (this.idLocal == null) return null;
      return {
        id: this.idLocal,
        descricao: this.descricao_idLocal
      }
    },
    grupoLocacao() {
      if (this.idGrupoLocacao == null) return null;
      return {
        id: this.idGrupoLocacao,
        nome: this.nome_idGrupoLocacao
      }
    },
  },
  methods: {
    open() {
      this.value = true;
    },
    close() {
      this.value = false;
    },
    dataMaxima() {
      return (parseInt(moment().format('YYYY')) + 1) +"-12";
    },
    dataMinima() {
      return (parseInt(moment().format('YYYY')) - 1) +"-01";
    },    
    async gerar() {
      if(!this.$refs.form.validate()) return;
      try {
        await gerarFaturasMensal( this.mesRef,
                                  this.tipoFatura,
                                  this.idLocal,
                                  this.tipoLocacao,
                                  this.idGrupoLocacao);
    
        this.$emit('faturas:gerada')
        this.$toast.success(`Faturas geradas com sucesso.`, {
          timeout: 3000
        })
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível gerar as faturas`, {
            timeout: 6000
          })
        }
      } finally {
        this.mesRef = null;
        this.value = false;   
      }
    }
  }
}
</script>

<style scoped>

</style>