<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
            :value="value"
            max-width="600px"
            persistent>
    <v-card class="d-flex flex-column">
      <v-card-title>
        Selecione o período
        <v-spacer/>
        <v-btn icon @click="value = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="py-4 flex-grow-1" v-if="value">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <strong>Tipo da Fatura</strong>
              <v-divider/>
              <v-radio-group v-model="item.tipoFatura" row 
                  :rules="[
                        this.$validators.string.required,
                  ]">
                <v-radio
                  label="Mensalidade"
                  value="MA"
                ></v-radio>
                <v-radio
                  label="Locação"
                  value="AL"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12">
              <search-box
                  :emit-all-properties="true"
                  label="* Cliente"
                  popup-label="Selecione um Cliente"
                  :id.sync="item.idCliente"
                  :nome.sync="item.nome_idCliente"
                  :hidden-clear="true"
                  :value="cliente"
                  :loader-fn="loaderClientes"
                  item-key="id"
                  item-text="nome"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Cliente encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="6">
              <v-text-field label="* Mês/Ano Inicio"
                    dense
                    type="month"
                    v-model="item.mesRefIni"
                    :max="dataMaxima()"
                    :min="dataMinima()"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
            </v-col>
            <v-col cols="6">
              <v-text-field label="* Mês/Ano Fim"
                    dense
                    type="month"
                    :max="dataMaxima()"
                    :min="dataMinima()"                    
                    v-model="item.mesRefFim"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="primary" @click="value = false">Cancelar</v-btn>
        <v-btn color="primary" @click="gerar" :disabled="!value">Gerar Faturas</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";
import {gerarFaturasPorPeriodo} from "../../../api/faturas";
import {findAll as findAllClientes} from "../../../api/clientes";
import {ApiError} from "../../../api/core";
import * as moment from 'moment';

export default {
  name: "GerarFaturasPorPeriodoDialog",
  components: {SearchBox},
  data () {
    return {
      valid: true,
      value: false,
      item: {
        idCliente: null,
        nome_idCliente: null,
        mesRefIni: null,
        mesRefFim: null,
        tipoFatura: null
      },
    }
  },
  computed: {
    cliente() {
      if(this.item.idCliente == null) return null;
      return {
        id: this.item.idCliente,
        nome: this.item.nome_idCliente
      }
    },
  },
  methods: {
    dataMaxima() {
      return (parseInt(moment().format('YYYY')) + 1) +"-12";
    },
    dataMinima() {
      return (parseInt(moment().format('YYYY')) - 1) +"-01";
    },
    open() {
      this.value = true;
    },
    close() {
      this.value = false;
    },
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    async gerar() {
      if(!this.$refs.form.validate()) return;
      try {
        await gerarFaturasPorPeriodo(this.item);
        this.$emit('faturasPeriodo:gerada')
        this.$toast.success(`Faturas por período geradas com sucesso.`, {
          timeout: 3000
        })
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível gerar as faturas`, {
            timeout: 6000
          })
        }
      } finally {
        this.mesRef = {};
        this.value = false;   
      }
    }
  }
}
</script>

<style scoped>

</style>